import { AdobeSearchClick } from "./searchWidgetAdobe";
import { cleverTapSearch } from "./searchWidgetClevertap";
import { format, differenceInCalendarDays } from 'date-fns';
import { utmUrlParam } from "../../services/commonUrlParams";


module.exports.HandleSearch = async function (startDate, endDate, value1, propertyType, destinationId, rooms, autoCompleteTitle, sameDateError, DefaultSelectValue, propertyName, propertyCount, search_type, dest_type, property_type_name, mop) {
    let destinationError = "", dateRangeError = "", UrlString;
    AdobeSearchClick(startDate, endDate, value1, propertyType, destinationId, rooms, autoCompleteTitle, mop);

    if ((value1 === '') && (sameDateError) || (value1.trim() !== DefaultSelectValue.trim())) {
        destinationError = 'Please select destination'; value1 = ''; dateRangeError = 'Invalid checkin and checkout date';
    } else if (value1 === '') {
        destinationError = 'Please select destination'; value1 = '';
    } else if (sameDateError) {
        dateRangeError = 'Invalid checkin and checkout date'
    } else {
        let daterange = (format(startDate, 'dd MM yyyy') + ' - ' + format(endDate, 'dd MM yyyy'));
        // let value = value1.trim();
        let localValue = JSON.parse(localStorage.getItem('SearchLocation')) !== null ? JSON.parse(localStorage.getItem('SearchLocation')) : [];
        // let setLocalVal = []
        await SearchLocationProcess1(value1.trim(), value1.trim(), daterange, propertyName, rooms, destinationId, propertyCount, propertyType, search_type, dest_type, property_type_name);

        let checkin = format(startDate, 'yyyy-MM-dd');
        let checkout = format(endDate, 'yyyy-MM-dd');
        let paxData = await getPaxData(rooms);
        let roomData = paxData.roomString
        let utmUrl = utmUrlParam(window.location.href)
        let nameArray = value1.trim().split(',');
        nameArray = nameArray.map(ele => ele ? ele.trim() : "");
        let region = nameArray.slice(0, -1).join(', ');
        UrlString = propertyType !== "hotel" ?
            'searchdetail?region=' + encodeURIComponent(region) +
            '&mop=' + mop +
            '&view=tiles&country=' + nameArray[nameArray.length - 1].trim() +
            '&destination_id=' + destinationId +
            '&search_type=' + search_type +
            '&checkin=' + checkin +
            '&checkout=' + checkout +
            '&rooms=' + roomData + utmUrl
            :
            'hoteldetails?searchtext=' + encodeURIComponent(value1) +
            '&mop=' + mop +
            '&checkin=' + checkin +
            '&checkout=' + checkout +
            '&rooms=' + roomData +
            '&vid=' + destinationId + utmUrl
        try {
            cleverTapSearch(startDate, endDate, rooms, value1, destinationId, autoCompleteTitle, localValue, propertyType, UrlString);
            // hoteSearchBranchEvent(startDate, endDate, paxData.adultCount, paxData.childCount, propertyType, destinationId, value1, rooms, autoCompleteTitle);
        } catch (error) {
            console.log("err", error);
        }

    }
    return { destinationError, value1, dateRangeError, UrlString }
}



async function SearchLocationProcess1(location, label, daterange, propertyName, rooms, destinationId, propertyCount, propertyType, search_type, dest_type, property_type_name) {
    let searchObj = {
        location: location,
        label: label,
        daterange: daterange,
        propertyName: propertyName,
        PaxArray: rooms,
        paxConfig: rooms.adults + ' Adult ' + rooms.childrens + ' Child',
        roomConfig: rooms.rooms.length + ' Room : ',
        destination_id: destinationId,
        property_count: propertyCount,
        type: propertyType,
        search_type: search_type,
        dest_type: dest_type,
        property_type_name: property_type_name,
    }
    let duplicateobjArr = []
    let searchLocationArr = JSON.parse(localStorage.getItem("SearchLocation")) ? JSON.parse(localStorage.getItem("SearchLocation")) : [];
    // get location object if already present in localstorage 
    if (searchLocationArr && searchLocationArr.length) {
        duplicateobjArr = searchLocationArr.filter(ele => parseInt(ele.destination_id) == parseInt(searchObj.destination_id) && parseInt(ele.search_type) == parseInt(searchObj.search_type))
    }

    // remove duplicateobjArr location object from localstorage array 
    if (duplicateobjArr.length > 0) {
        searchLocationArr = searchLocationArr.filter(ele => ele != duplicateobjArr[0])
    }

    searchLocationArr.splice(0, 0, searchObj);
    // to remove duplicate entries from searchLocationArr
    searchLocationArr = searchLocationArr.filter((value, index, self) => {
        return index === self.findIndex((t) => {
            return (t?.destination_id === value.destination_id && t?.search_type === value.search_type)
        })
    })
    localStorage.setItem("SearchLocation", JSON.stringify(searchLocationArr))

    return searchLocationArr
}

async function getPaxData(rooms) {
    let roomString = "", childCount = 0, adultCount = 0;
    rooms.rooms.map((fact) => {
        let adult = parseInt(fact.adult) === 0 ? 0 : parseInt(fact.adult);
        let child = parseInt(fact.children) === 0 ? 0 : parseInt(fact.children);
        let childAge = '';
        if (fact.childAge.length > 0) {
            fact.childAge.map((childs, id) => {
                childAge = childAge + childs.age + ","
            })
        }
        var childage = childAge.substring(0, childAge.length - 1)
        roomString = roomString === '' ? roomString + adult + 'a-' + child + 'c-' + childage : roomString + '_' + adult + 'a-' + child + 'c-' + childage
        adultCount = adultCount + adult;
        childCount = childCount + child
    })

    return { roomString, adultCount, childCount }

}

async function hoteSearchBranchEvent(startDate, endDate, adultCount, childCount, propertyType, destinationId, value1, rooms, autoCompleteTitle) {

    let checkinDate = format(startDate, 'MM-dd-yyyy');
    let checkoutDate = format(endDate, 'MM-dd-yyyy');
    let custom_data = {
        "Hotel Search Term": value1,
        "Hotel Search Category": propertyType == "" ? localValue[0].type : propertyType,
        "Hotel City": destinationId.toString().length <= 6 ? value1.split(',')[0].trim() : '',
        "Hotel Property": destinationId.toString().length <= 6 ? "" : value1,
        "Hotel Area": "",
        "Hotel Country": destinationId.toString().length <= 6 ? value1.split(',')[1].trim() : '',
        "Hotel Checking Date": checkinDate,
        "Hotel Check Out Date": checkoutDate,
        "Hotel Traveller Adult": adultCount,
        "Hotel Traveller Child": childCount,
        "Hotel No Of Room": rooms.rooms.length,
        "Hotel No Of Night": differenceInCalendarDays(new Date(endDate), new Date(startDate)),
        "Category": "None",
        "Hotel Search Type": autoCompleteTitle == 'Popular Destinations' ? 'Popular' : autoCompleteTitle == 'Suggestions' ? 'Suggested' : 'Recent',
    }

    try {
        setTimeout(() => {
            if (window.branch) {
                window.branch.logEvent("Hotel_search", custom_data, (err => { console.log(err); }))
            }
        }, 500)
    }
    catch (error) {
        console.log("Branch Event - Error - Hotel Search Data", error);
    }
}

export const preg_quote = function (str, delimiter) {
    return (str + '').replace(new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\' + (delimiter || '') + '-]', 'g'), '\\$&')
}

export const getAutoCompleteImg = function (dest_type) {
    let autocompleteImg = ""
    if (dest_type == "Region" || dest_type == "City" || dest_type == "city" || dest_type == "Area" || dest_type == "District") {
        autocompleteImg = "/nextHotels/img/autocomplete-icon/location.png";
    } else if (dest_type == "Train Station") {
        autocompleteImg = "/nextHotels/img/autocomplete-icon/train-station.png";
    } else if (dest_type == "Metro Station") {
        autocompleteImg = "/nextHotels/img/autocomplete-icon/metro-station.png";
    } else if (dest_type == "Airport") {
        autocompleteImg = "/nextHotels/img/autocomplete-icon/airport.png";
    } else if (dest_type == "Landmark") {
        autocompleteImg = "/nextHotels/img/autocomplete-icon/places-of-interest.png";
    } else if (dest_type == "Hotel" || dest_type == "Property") {
        autocompleteImg = "/nextHotels/img/autocomplete-icon/hotel-property.png";
    }
    return autocompleteImg
}