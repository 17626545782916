import { Adobe } from '../../actions/AdobeLayerNew';
import dateFormat from 'dateformat';



let userInfo = {}
let pageName = "hotelsnew-home";
let subCategory1 = "home";
let milesCategory = "None";

module.exports.setUserInfo = function (loggedIn_Info) {
    userInfo.JpNumber = loggedIn_Info?.isLoggedIn ? loggedIn_Info?.member_number : '';
    userInfo.tier = loggedIn_Info?.isLoggedIn ? loggedIn_Info?.tier : '';
    userInfo.pointsBalance = loggedIn_Info?.isLoggedIn ? loggedIn_Info?.points_balance : '';
    userInfo.loginStatus = loggedIn_Info?.isLoggedIn ? "LOGGED-IN" : "ANONYMOUS";
}

module.exports.AdobeErrorEvent = function () {
    try {
        // Adobe.errorTracking(pageName,subCategory1,milesCategory,JPNumber,tier,pointsBalance,loginStatus,errorDescription,errorCode)
        Adobe.errorTracking(pageName, subCategory1, milesCategory, userInfo, "Oop's Something Went Wrong", "404");
    } catch {
        console.log("error")
    }
}


module.exports.AdobeSearchClick=function (startDate,endDate,value1,propertyType,destinationId,rooms,autoCompleteTitle,mop){
        // let mop=new URLSearchParams(window.location.search).get('mop') 
        let destinationCategory;
        let destinationterm = propertyType == undefined ? JSON.parse(localStorage.getItem("SearchLocation"))?.[0]?.type : propertyType;
        if (destinationterm == "hotel") 
            destinationCategory = "hotel name";
         else 
            destinationCategory = "Area";
        
        try {
            
            let searchInfo ={
                searchResultType: "Original Search",
                destination: value1,
                destinationCategory: destinationCategory,
                propertyName: propertyType == "hotel" ? value1 : "",
                bookingDate: `${dateFormat(startDate, "dd-mmm-yyyy")}|${dateFormat(endDate, "dd-mmm-yyyy")}`,
                noofNights: noofNights(startDate, endDate),
                guests: `${rooms.adults}:${rooms.childrens}`,
                rooms: `${rooms.rooms.length}`,
                searchTerm:value1,
                searchType: autoCompleteTitle == "Suggestions" ? "Suggested" : autoCompleteTitle == "Recent Searches" ? "Recent" : "Popular",
                totalTravellers: `${rooms.adults + rooms.childrens}`,
            }
            let hotelId=destinationCategory == "Area" ? "" : (destinationId || "")
                                //    (pageName, subCategory1, milesCategory, userInfo, hotelId, searchInfo,sortValue,filterUsed)
            Adobe.InterbookSearchClick(pageName, subCategory1, milesCategory, userInfo, hotelId, searchInfo,"Star Rating","");
        } catch (err) {
            console.log(err);
        }
}


const noofNights = (datestart, dateend) => {
    const days = dateend.getTime() - datestart.getTime();
    return days / (1000 * 60 * 60 * 24)
}