
import {  differenceInCalendarDays } from 'date-fns';
import CallAdobe from "../../actions/data";


module.exports.cleverTapSearch=async function(startDate,endDate,rooms,value1,destinationId,autoCompleteTitle,localValue,propertyType,UrlString){


try {
    window.clevertapData["Global"]["Category"] = "None";
    window.clevertapData["Global"]["Channel"] = window.screen.availWidth <= 1023 ? "Mweb" : "Web";
    window.clevertapData["Global"]["CleverTap ID"] = window.clevertap?.getCleverTapID();

    window.clevertapData["Search"] = {
        'Action': 'Original Search',
        'Destination Url': window.location.origin + UrlString,
        'Hotel Search Term': value1,
        'Hotel Search Category': propertyType == "" ? localValue[0].type : propertyType,
        'Hotel City':destinationId.toString().length <= 6 ? value1.split(',')[0].trim() : '',
        'City ID':destinationId.toString().length <= 6 ? "" + destinationId : '',
        'Hotel Property':destinationId.toString().length <= 6 ? "" : value1, //localValue[0].label
        'Hotel Area':destinationId.toString().length <= 6 ?value1.split(',')[0].trim() : '',
        'Hotel Country':destinationId.toString().length <= 6 ?value1.split(',')[1].trim() : '',
        'Hotel Checking Date': new Date(startDate),
        'Hotel Check Out Date': new Date(endDate),
        'Hotel Traveller Adult': rooms.adults,
        'Hotel Traveller Child': rooms.childrens,
        'Total Travellers': rooms.adults + rooms.childrens,
        'Hotel No Of Room ': rooms.rooms.length,
        'Hotel No Of Night': differenceInCalendarDays(new Date(endDate), new Date(startDate)),
        'Hotel Search Type': autoCompleteTitle == 'Popular Destinations' ? 'Popular' : autoCompleteTitle == 'Suggestions' ? 'Suggested' : 'Recent', //Near Buy/Popular/Recent
        'Hotel Property ID': '' + destinationId.toString().length > 6 ? "" + destinationId : '',
        'Filter Used': "",
        'Sort Used': "",
        'Safe Travel': "",
        "No Of Days Before Booking": differenceInCalendarDays(new Date(startDate), new Date()),
        'Search Criteria': 'Original Search:' + value1 + ":" + startDate.toLocaleString().split(',')[0] + ":" + endDate.toLocaleString().split(',')[0] + ":" + rooms.adults + ":" + rooms.childrens + ":" + rooms.rooms.length + ":" + differenceInCalendarDays(new Date(endDate), new Date(startDate)),
        'Search Modified Field': "",
        // 'Payment Type': "Accrual"
    };
    CallAdobe.CallCT('Search');
} catch (e) {
    console.log("ct err:", e)
}
}